.toggle-control {
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.toggle-control input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.toggle-control input:checked ~ .control {
  background-color: rgb(93, 172, 132);
}
.toggle-control input:checked ~ .control:after {
  left: 15px;
}
.toggle-control .control {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 30px;
  border-radius: 25px;
  background-color: rgb(216, 216, 216);
  transition: background-color 0.1s ease-in;
}
.toggle-control .control:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  width: 10px;
  height: 10px;
  border-radius: 25px;
  background: white;
  transition: left 0.1s ease-in;
}

.setInvisible {
  display: none !important;
}

.btn-smol {
  height: 50px;
  line-height: 50px;
  width: 50px;
  font-size: 1em;
  border-radius: 50%;
  border: none;
  background-color: rgba(0, 0, 0);
  color: white;
  text-align: center;
  cursor: pointer;
}


