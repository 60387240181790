#root, body, html {
    height: 100%;
}

body{
    background: url(../../utils/assets/back.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

body:after{
    content:"";
    position:fixed; /* stretch a fixed position to the whole screen */
    top:0;
    height:100vh; /* fix for mobile browser address bar appearing disappearing */
    left:0;
    right:0;
    z-index:-1; /* needed to keep in the background */
    background: url(../../utils/assets/back.png) center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.back1{
    background: url(../../utils/assets/back.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.back1:after{
    content:"";
    position:fixed; /* stretch a fixed position to the whole screen */
    top:0;
    height:100vh; /* fix for mobile browser address bar appearing disappearing */
    left:0;
    right:0;
    z-index:-1; /* needed to keep in the background */
    background: url(../../utils/assets/back.png) center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.back2{
    background: url(../../utils/assets/back2.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.back2:after{
    content:"";
    position:fixed; /* stretch a fixed position to the whole screen */
    top:0;
    height:100vh; /* fix for mobile browser address bar appearing disappearing */
    left:0;
    right:0;
    z-index:-1; /* needed to keep in the background */
    background: url(../../utils/assets/back2.png) center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.back3{
    background: url(../../utils/assets/back3.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.back3:after{
    content:"";
    position:fixed; /* stretch a fixed position to the whole screen */
    top:0;
    height:100vh; /* fix for mobile browser address bar appearing disappearing */
    left:0;
    right:0;
    z-index:-1; /* needed to keep in the background */
    background: url(../../utils/assets/back3.png) center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.back4{
    background: url(../../utils/assets/back4.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.back4:after{
    content:"";
    position:fixed; /* stretch a fixed position to the whole screen */
    top:0;
    height:100vh; /* fix for mobile browser address bar appearing disappearing */
    left:0;
    right:0;
    z-index:-1; /* needed to keep in the background */
    background: url(../../utils/assets/back4.png) center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
