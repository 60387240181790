#scorecard {
    margin-top: 50px;
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  
  #scorecard td, #scorecard th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  #scorecard tr:nth-child(even){background-color: #f2f2f2;}
  
  #scorecard tr:hover {background-color: #ddd;}
  
  #scorecard th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #EAF0F0;
  }
